

import Storage from "@/util/Storage";
import {getShopSchoolDayIncome} from "@/Api/Platform/shop";
import {getExpSchoolDayIncome} from "@/Api/Platform/exp";
import {filterToArray} from "@/views/PlatformAdmin/Util";


export function getExpDayIncome(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getExpSchoolDayIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list: filterToArray(res.data,"0","dateTime","orderCount","amount","schoolFee","serviceFee","logistics","weixinFee",)
        }
    }))
}

export function getShopDayIncome(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getShopSchoolDayIncome({ userId,token },body).then(res=>{
        return {
            rawList:res.data,
            list:filterToArray(res.data,"无","dateTime","orderCount","income","storeFee","schoolFee","serviceFee","weixinFee")
        }
    }))
}
